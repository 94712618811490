import mixpanelBrowser from "mixpanel-browser";

const mixpanel = {
  init: () => {
    if (process.env.REACT_APP_TRACK_ANALYTICS === "true") {
      mixpanelBrowser.init(process.env.REACT_APP_MIXPANEL_TOKEN);
    }
  },

  track: (eventName, properties) => {
    if (process.env.REACT_APP_TRACK_ANALYTICS === "true") {
      mixpanelBrowser.track(eventName, properties);
    }
  },

  getDistinctId: () => {
    if (process.env.REACT_APP_TRACK_ANALYTICS === "true") {
      return mixpanelBrowser.get_distinct_id();
    }

    return null;
  },

  alias: (alias) => {
    if (process.env.REACT_APP_TRACK_ANALYTICS === "true") {
      return mixpanelBrowser.alias(alias);
    }
  },

  identify: (id) => {
    if (process.env.REACT_APP_TRACK_ANALYTICS === "true") {
      return mixpanelBrowser.identify(id);
    }
  },

  register: (properties) => {
    if (process.env.REACT_APP_TRACK_ANALYTICS === "true") {
      return mixpanelBrowser.register(properties);
    }
  },

  setPeople: (properties) => {
    if (process.env.REACT_APP_TRACK_ANALYTICS === "true") {
      return mixpanelBrowser.people.set(properties);
    }
  },
};

export default mixpanel;
